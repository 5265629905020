const Layout = () => import("@/layout/index.vue");

export default {
  path: "/",
  name: "Home",
  component: Layout,
  redirect: "/index",
  hidden: false,
  meta: {
    icon: "ep:data-analysis",
    title: "仪表盘"
  },
  children: [
    {
      path: "/index",
      name: "/index",
      hidden: false,
      component: () => import("@/views/index/index.vue"),
      meta: {
        title: "仪表盘"
      }
    },
    {
      path: "/error/403",
      name: "403",
      component: () => import("@/views/error/403.vue"),
      meta: {
        title: "403"
      },
      hidden: true
    },
    {
      path: "/error/404",
      name: "404",
      component: () => import("@/views/error/404.vue"),
      meta: {
        title: "404"
      },
      hidden: true
    },
    {
      path: "/error/500",
      name: "500",
      component: () => import("@/views/error/500.vue"),
      meta: {
        title: "500"
      },
      hidden: true
    }
  ]
} satisfies RouteConfigsTable;
