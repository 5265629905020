/* eslint-disable */
import type { PureHttpRequestConfig } from "./http/types.d";

export const fillterQuery = (config: PureHttpRequestConfig) => {
  if (config.data) {
    Object.entries(config.data).forEach(([key, value]) => {
      if (
        (typeof value === "string" && value.trim() === "") ||
        value === null
      ) {
        delete config.data[key];
      }
    });
  }

  if (config.params) {
    Object.entries(config.params).forEach(([key, value]) => {
      if (
        (typeof value === "string" && value.trim() === "") ||
        value === null
      ) {
        delete config.params[key];
      }
    });
  }
};
