export default {
  path: "/userCenter",
  name: "/userCenter",
  hidden: false,
  component: () => import("@/views/userCenter/index.vue"),
  meta: {
    icon: "ri:user-5-line",
    title: "个人中心"
  }
} satisfies RouteConfigsTable;
