export default {
  path: "/subordinateRebetList",
  name: "/subordinateRebetList",
  hidden: false,
  component: () => import("@/views/subordinateRebetList/index.vue"),
  meta: {
    icon: "ep:document",
    title: "下级投注记录"
  }
} satisfies RouteConfigsTable;
