export default {
  path: "/subordinateData",
  name: "/subordinateData",
  hidden: false,
  component: () => import("@/views/subordinateData/index.vue"),
  meta: {
    icon: "ri:compasses-line",
    title: "下级报表"
  }
} satisfies RouteConfigsTable;
